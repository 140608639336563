import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { ButtonItens, ButtonRow } from '../../style';
import { IoMdAddCircle } from 'react-icons/io';
import { useIdenficacaoContabil } from '../../IdenficacaoContabilContext';
import { ModalNovoImposto } from '../../Components/ModalAbaImpostos';
import { IoMdRemoveCircle } from 'react-icons/io';
import { deleteItens } from '~/utils/masterDetail';
import ToggleDefault from '~/components/ToggleDefault';

export const TabImposto: React.FC = () => {
  const {
    handleOpenModalImposto,
    rowsImpostos,
    setRowsImpostos,

    masterDetail,
    setMasterDetail,
    flgIgnoraPisCofinsWatch,
    flgIgnoraIcmsNFClienteWatch,
    setValue,
  } = useIdenficacaoContabil();

  const associadoColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: 'uuid',
      headerName: 'uuid',
      hide: true,
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: 'campo',
      headerName: 'Campo',
      flex: 2,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.campo.label}</>;
      },
    },
    {
      field: 'conta_debito_reduzida',
      headerName: 'Conta Débito',
      flex: 1,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.conta_debito}</>;
      },
    },
    {
      field: 'conta_credito_reduzida',
      headerName: 'Conta Crédito',
      flex: 1,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.conta_credito}</>;
      },
    },
    {
      field: 'historico_padrao',
      headerName: 'Histórico',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return <>{row.historico_padrao.value}</>;
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      headerClassName: 'hideRightSeparator',
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: { row: any }) => {
        const onDelete = () => {
          const { row } = params;
          handleRemoveImpostos(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onDelete}>
              <IoMdRemoveCircle size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleRemoveImpostos = async (uuid: string) => {
    const autorizadosDetail: any[] = await deleteItens(
      'impostos',
      uuid,
      masterDetail,
      setMasterDetail,
    );
    setRowsImpostos(autorizadosDetail);
  };

  return (
    <>
      <Row>
        <Col
          md={12}
          sm={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonItens onClick={handleOpenModalImposto} type="button">
            <IoMdAddCircle color="#2D7BE8" />
            <span>Incluir Novo</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} style={{ marginTop: '10px', height: '400px' }}>
          <DataGrid
            className="myDataGrid"
            rows={rowsImpostos}
            columns={associadoColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar columnas',
            }}
          />
        </Col>
        <ModalNovoImposto />
      </Row>
      <br />
      <Row>
        <Col md={2} sm={12}>
          <ToggleDefault
            labelText="Ignora PIS/COFINS?"
            setChecked={flgIgnoraPisCofinsWatch}
            onSwitch={() => {
              setValue('flg_ignora_pis_cofins', !flgIgnoraPisCofinsWatch);
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <ToggleDefault
            labelText="Ignora ICMS na NF de Cliente?"
            setChecked={flgIgnoraIcmsNFClienteWatch}
            onSwitch={() => {
              setValue(
                'flg_ignora_icms_nf_cliente',
                !flgIgnoraIcmsNFClienteWatch,
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};
