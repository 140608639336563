import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputSelect } from '~/components/NovosInputs';
import { useIdenficacaoContabil } from '~/pages/IdentificacaoContabil/IdenficacaoContabilContext';
import { SelectProps } from '../../types';

export const TabSpedPisConfins: React.FC = () => {
  const {
    register,
    control,
    errors,
    setValue,
    clearErrors,
    optionBcCred,
    optionTipoCred,
  } = useIdenficacaoContabil();

  return (
    <>
      <Row>
        <Col md={4} sm={12}>
          <InputSelect
            label="Código BC Crédito"
            placeholder="Selecione o Código BC Crédito"
            name="cod_bc_cred"
            listaHeight="250px"
            register={register}
            options={optionBcCred}
            isError={!!errors.cod_bc_cred}
            control={control}
            changeSelected={(selected: SelectProps) => {
              clearErrors('cod_bc_cred');
              setValue('cod_bc_cred', selected);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4} sm={12}>
          <InputSelect
            label="Código Tipo Crédito"
            name="cod_tipo_cred"
            listaHeight="200px"
            placeholder="Selecione o Código Tipo Crédito"
            register={register}
            options={optionTipoCred}
            isError={!!errors.cod_tipo_cred}
            control={control}
            changeSelected={(selected: SelectProps) => {
              clearErrors('cod_tipo_cred');
              setValue('cod_tipo_cred', selected);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
